import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { License } from '../models/api/License';
import { Partner } from '../models/api/Partner';
import { AuthenticationService } from './authentication.service';
import { DashboardMetadata } from '../models/api/DashboardMetadata';
import { Discounts } from '../models/api/Discounts';
import { ReplicationStatus } from '../models/replication/ReplicationStatus';
import { ReplicationSettings } from '../models/replication/ReplicationSettings';
import { MigratorStatus } from '../models/replication/MigratorStatus';
import { ReplicationError } from '../models/replication/ReplicationError';
import { LicensesViewModel } from '../models/api/LicensesViewModel';
import { PartnerUser } from '../models/api/PartnerUser';
import { PartnerCertifications } from '../models/api/PartnerCertifications';
import { UserRequest } from '../models/api/UserRequest';
import { LicenseEntry } from '../models/api/LicenseEntry';
import { SubscriptionEntry } from '../models/api/SubscriptionEntry';
import { PaymentCurrency } from '../models/saltpay/PaymentCurrency';
import { History } from '../models/api/History';
import { HttpResponse } from '@angular/common/http';
import { PartnerProgramStatus } from '../models/api/PartnerProgramStatus';
import { PartnerProgram } from '../models/api/PartnerProgram';
import { PartnerProgramAttachments } from '../models/api/PartnerProgramAttachments';
import { LicenseMobileKey } from 'src/app/models/api/LicenseMobileKey';
import { BaseFilter } from '@app/models/api/BaseFilter';
import { PartnerFilter } from '@app/models/api/PartnerFilter';
import { LicenseForSearch } from '@app/models/api/LicenseForSearch';
import { LicenseCoutriesAndProductLines } from '@app/models/api/LicenseCoutriesAndProductLines';
import { LicensesAndCurrencies } from '@app/models/api/LicensesAndCurrencies';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private authService: AuthenticationService) {
    this.partner = new BehaviorSubject<Partner>(null);
    this.partnerUsers = new BehaviorSubject<PartnerUser[]>(null);
    this.partnerUserRequests = new BehaviorSubject<UserRequest[]>(null);
    this.partnerCertifications = new BehaviorSubject<PartnerCertifications>(null);
    this.discounts = new BehaviorSubject<Discounts>(new Discounts());
    this.replicationStatus = new BehaviorSubject<ReplicationStatus>(new ReplicationStatus());
    this.replicationSettings = new BehaviorSubject<ReplicationSettings[]>([]);
    this.migratorStatuses = new BehaviorSubject<MigratorStatus[]>([]);
    this.replicationErrors = new BehaviorSubject<ReplicationError[]>([]);
    this.AutoBillChangeResult = new BehaviorSubject<string>(null);

    this.forceLicensesUpdateMigratorStatus = new BehaviorSubject<MigratorStatus>(null);
    this.licenseValueEntries = new BehaviorSubject<LicenseEntry[]>(null);
    this.licenseMobileValueKeys = new BehaviorSubject<LicenseMobileKey[]>(null);
    this.subscriptionValueEntries = new BehaviorSubject<SubscriptionEntry[]>(null);
    this.history = new BehaviorSubject<History>(null);
  }

  public history: BehaviorSubject<History>;
  public partner: BehaviorSubject<Partner>;
  public partnerUsers: BehaviorSubject<PartnerUser[]>;
  public partnerUserRequests: BehaviorSubject<UserRequest[]>;
  public partnerCertifications: BehaviorSubject<PartnerCertifications>;
  public discounts: BehaviorSubject<Discounts>;
  private discountsUpdated: boolean = false;

  public replicationStatus: BehaviorSubject<ReplicationStatus>;
  public replicationSettings: BehaviorSubject<ReplicationSettings[]>;
  public migratorStatuses: BehaviorSubject<MigratorStatus[]>;
  public forceLicensesUpdateMigratorStatus: BehaviorSubject<MigratorStatus>;
  public replicationErrors: BehaviorSubject<ReplicationError[]>;

  public licenseValueEntries: BehaviorSubject<LicenseEntry[]>;
  public licenseMobileValueKeys: BehaviorSubject<LicenseMobileKey[]>;
  public subscriptionValueEntries: BehaviorSubject<SubscriptionEntry[]>;
  public AutoBillChangeResult: BehaviorSubject<string>;
  public selectedLicenseForValue: License = null;

  getLicenses(model: PartnerFilter): Observable<any> {
    model.partnerNo = this.authService.currentSelectedPartner.no;
    return this.http.post<any>('/Licenses/GetForPartner/', model);
  }

  getLicenseCoutriesAndProductLines(isSubscription: number): Observable<LicenseCoutriesAndProductLines> {
    return this.http.post<LicenseCoutriesAndProductLines>('/Licenses/GetLicenseCoutriesAndProductLines/', {
      partnerNo: this.authService.currentSelectedPartner.no,
      isSubscription: isSubscription,
    });
  }

  getLicenseMobileKeysCount(LicenseNo: string, isSubscription: boolean): any {
    return this.http.get<any>('/Licenses/MobileLicenses/' + isSubscription + '/' + LicenseNo);
  }

  getLicenseValue(no: string): void {
    this.http
      .post<LicenseEntry[]>('/Licenses/GetLicenseValue/', {
        no: no,
      })
      .subscribe((data) => {
        this.licenseValueEntries.next(data);
      });
  }

  getLicenseMobileKeys(no: string): void {
    this.http
      .post<LicenseMobileKey[]>('/Licenses/GetLicenseMobileKeys/', {
        no: no,
      })
      .subscribe((data) => {
        this.licenseMobileValueKeys.next(data);
      });
  }

  getPartnerProgramStatuses(): Observable<PartnerProgramStatus[]> {
    return this.http.get<PartnerProgramStatus[]>('/PartnerProgram/codestatusehf/', {});
  }

  getPartnerProgramAttachments(partnerProgramId: number): Observable<PartnerProgramAttachments[]> {
    return this.http.get<PartnerProgramAttachments[]>(
      '/PartnerProgramAttachment/getbypartnerprogram/' + partnerProgramId,
      {}
    );
  }

  getPartnerProgramLogo(partnerProgramId: number): Observable<PartnerProgramAttachments> {
    return this.http.get<PartnerProgramAttachments>('/PartnerProgram/getPartnerProgramLogo/' + partnerProgramId, {});
  }

  deletePartnerProgramAttachment(attachmentId: any): Observable<any> {
    return this.http.get<any>('/PartnerProgramAttachment/delete/' + attachmentId, {});
  }

  deletePartnerProgramLogo(partnerProgramId: any): Observable<any> {
    return this.http.get<any>('/PartnerProgram/deleteLogo/' + partnerProgramId, {});
  }

  downloadPartnerProgramAttachment(attachmentId: any): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>('/PartnerProgramAttachment/download/' + attachmentId, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  downloadPartnerProgramLogo(partnerProgramId: any): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>('/PartnerProgram/downloadLogo/' + partnerProgramId, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  downloadPartnerProgramAttachmentsByProgram(
    programCode: string,
    programStatus: string
  ): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>('/PartnerProgramAttachment/downloadByProgram/' + programCode + '/' + programStatus, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  createPartnerProgram(model: any): Observable<any> {
    return this.http.post<any>('/PartnerProgram/create/', model);
  }

  updatePartnerProgram(model: any): Observable<PartnerProgram> {
    return this.http.post<PartnerProgram>('/PartnerProgram/update/', model);
  }

  getPartnerPrograms(model: BaseFilter): Observable<any> {
    return this.http.post<any>('/PartnerProgram/list/', model);
  }

  getPartnerProgramByid(id: any): Observable<PartnerProgram> {
    return this.http.get<PartnerProgram>('/PartnerProgram/' + id, {});
  }

  deletePartnerPrograms(id: any): Observable<any> {
    return this.http.get<any>('/PartnerProgram/delete/' + id, {});
  }

  getSubscriptionValue(no: string): void {
    this.http
      .post<SubscriptionEntry[]>('/Licenses/GetSubscriptionValue/', {
        licenseNo: no,
        partnerNo: this.authService.currentSelectedPartner.no,
      })
      .subscribe((data) => {
        this.subscriptionValueEntries.next(data);
      });
  }

  getDashboardMetadata(): Observable<DashboardMetadata> {
    return this.http.post<DashboardMetadata>('/Licenses/dashboard/', {
      no: this.authService.currentSelectedPartner.no,
    });
  }
  getlogs(model: BaseFilter): Observable<any> {
    return this.http.post<any>('/History/', model);
  }
  getOrders(model: PartnerFilter): Observable<any> {
    return this.http.post<any>('/Orders/GetForPartner/', model);
  }

  getLicensesAndCurrencies(partnerNo: string): Observable<LicensesAndCurrencies> {
    return this.http.get<LicensesAndCurrencies>('/Orders/GetLicensesAndCurrencies/' + partnerNo, {});
  }

  public checkUpdatePartner() {
    if (
      this.partner.value == null ||
      this.authService.currentSelectedPartner == null ||
      this.partner.value.customerNo != this.authService.currentSelectedPartner.no
    ) {
      this.getPartner(this.authService.currentSelectedPartner.no);
    }
  }

  getPartner(no: string): void {
    this.http.post<Partner>('/Partners/GetByNo/', { no: no }).subscribe((data) => {
      this.partner.next(data);
    });
  }

  getPartnerUsers(no: string): void {
    this.http.post<PartnerUser[]>('/Partners/GetUsersWithRoles/', { no: no }).subscribe((data) => {
      this.partnerUsers.next(data);
    });
  }

  getApiLicensesForSearch(model: BaseFilter): Observable<any> {
    return this.http.post<{ totalItems: number; results: LicenseForSearch[] }>(`/Licenses/GetForSearch`, model);
  }

  getUserRequestsForPartner(no: string): void {
    this.http
      .post<UserRequest[]>('/Partners/GetUserRequests/', {
        no: no,
      })
      .subscribe((data) => {
        this.partnerUserRequests.next(data);
      });
  }

  removePartnerUserRequest(id: Number): void {
    var requests = this.partnerUserRequests.value;
    this.partnerUserRequests.next(requests.filter((r) => r.id != id));
  }

  rejectUserRequest(id: Number): Observable<string> {
    return this.http.get('/Partners/RemoveUserRequest/' + id, { responseType: 'text' });
  }

  getPartnerCertifications(no: string): void {
    this.http.post<PartnerCertifications>('/Partners/GetPartnerCertifications/', { no: no }).subscribe((data) => {
      this.partnerCertifications.next(data);
    });
  }

  savePartnerUsers(partnerUsers: PartnerUser[]): Observable<boolean> {
    return this.http.post<boolean>('/Partners/SaveUsersWithRoles/', partnerUsers);
  }

  getDiscounts(no: string): Observable<Discounts> {
    if (!this.discountsUpdated) {
      this.http
        .post<Discounts>('/Partners/Discounts/', {
          no: no,
        })
        .subscribe((data) => {
          this.discounts.next(data);
        });
    }
    return this.discounts;
  }

  updateReplicationDashboard(): void {
    this.http.get<ReplicationStatus>('/EhfReplication/Status').subscribe((data) => {
      this.replicationStatus.next(data);
    });

    this.http.get<ReplicationSettings[]>('/EhfReplication/Settings').subscribe((data) => {
      this.replicationSettings.next(data);
      //console.log(data);
    });

    this.http.get<MigratorStatus[]>('/EhfReplication/StatusList').subscribe((data) => {
      this.migratorStatuses.next(data);
      //console.log(this.migratorStatuses);
    });

    this.http.get<ReplicationError[]>('/EhfReplication/ErrorList').subscribe((data) => {
      this.replicationErrors.next(data);
      //console.log(this.migratorStatuses);
    });
  }

  updateAutoBill(licenseNo, value): void {
    this.http.get('/Licenses/UpdateAutoBill/' + licenseNo + '/' + value, { responseType: 'text' }).subscribe((data) => {
      this.AutoBillChangeResult.next(data);
    });
  }

  forceUpdatePartnerLicenses(no): void {
    this.http.post<MigratorStatus>('/EhfReplication/UpdatePartnerLicenses/', { no: no }).subscribe((data) => {
      this.forceLicensesUpdateMigratorStatus.next(data);
    });
  }

  removeContact(no): Observable<boolean> {
    return this.http.post<boolean>('/Partners/RemoveContact/', { no: no });
  }

  createNewContact(
    email: string,
    firstname: string,
    lastname: string,
    customers: string[],
    roles: string[],
    partnershipRoles: string[],
    requestId: Number
  ) {
    return this.http.post(
      `/Partners/CreateContact/`,
      {
        email,
        firstname,
        lastname,
        customers,
        roles,
        partnershipRoles,
        requestId,
      },
      { responseType: 'text' }
    );
  }

  downloadInvoices(invoices: string[]): Observable<Blob> {
    return this.http.post(
      `/ReportAsPdf/DownloadInvoices`,
      {
        invoices: invoices,
      },
      {
        responseType: 'blob',
      }
    );
  }

  downloadAccountStatement(customerNo: string, startDate: string, endDate: string): Observable<HttpResponse<Blob>> {
    return this.http.post<Blob>(
      `/ReportAsPdf/DownloadAccountStatement`,
      {
        customerNo,
        startDate,
        endDate,
      },
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }
  registerSaltPayPayment(payment: PaymentCurrency): Observable<boolean> {
    return this.http.post<boolean>('/Saltpay/register-payment/', payment);
  }

  getNextPaymentId(): Observable<Number> {
    return this.http.get<Number>('/Saltpay/get-next-id/');
  }

  getPaymentsHistory(model: BaseFilter): Observable<any> {
    return this.http.post<any>('/Saltpay/payment-history/', model);
  }

  downloadBalanceToDate(customerNo: string, endDate: string): Observable<HttpResponse<Blob>> {
    return this.http.post<Blob>(
      `/ReportAsPdf/DownloadBalanceToDate`,
      {
        customerNo,
        endDate,
      },
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }

  sendEmailToAccounting(model: any): Observable<string> {
    return this.http.post(`/ReportAsPdf/SendEmailToAccounting`, model, { responseType: 'text' });
  }

  updateCustomerEmail(licenseNo, updatedCustomerEmail): Observable<boolean> {
    return this.http.get<boolean>('/Licenses/UpdateCustomerEmail/' + licenseNo + '/' + updatedCustomerEmail);
  }
}
